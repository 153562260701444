<template>
  <div class="mt-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-datepicker
                name="book_date"
                label="Data Registrazione Dal"
                v-model="filter.byCalendar.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="book_date"
                label="Data Registrazione al"
                v-model="filter.byCalendar.to"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="cooperators"
                label="Collaborazioni"
                v-model="filter.byBrokerEnhanced.cooperators"
                :options="cooperators"
                :taggable="true"
                :multiple="true"
                :closeOnSelect="false"
              />
              <b-button
                :pressed.sync="myToggle"
                variant="light"
                class="mb-2"
                @click="
                  myToggle ? selectAllCooperators() : deselectAllCooperators()
                "
                >{{
                  myToggle ? "Deseleziona tutti" : "Seleziona tutti"
                }}</b-button
              >
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
          <b-button-group
            v-b-toggle:collapse-1-inner
            class="my-2 filter-button-group"
            v-show="customFilters.length > 0"
          >
            <span class="when-open">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            <span class="when-closed">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            Altri Filtri
          </b-button-group>

          <b-collapse id="collapse-1-inner" class="mt-2">
            <div v-show="customFilters.length > 0">
              <p>Attributi personalizzati</p>

              <b-row v-for="(element, index) in customFilters" :key="index">
                <custom-filter
                  v-model="filter.byAttribute[element.key]"
                  :element="element"
                />
              </b-row>
            </div>
          </b-collapse>
        </b-card>
      </b-form>
    </b-collapse>
    <export-options
      :exportUrl="exportUrl"
      :repository="repository"
      :resource="resource"
      :tableRef="$refs[tableRef]"
      :filter="filter"
      :options="[
        {
          code: 'CONREGCOL',
          label: 'Report controllo registrazioni',
          formats: ['csv', 'pdf'],
        },
        {
          code: 'RENCOLLAB',
          label: 'Rendiconto singola collaborazione',
          formats: ['pdf'],
          // usare extra solo per variabili particolari, non per beForm:
          // se ne occupa ExportOptions.fetchForm, nei casi in cui serve.
          // extra: {
          //   beFormCreate: beFormCreate,
          //   beRulesCreate: beFormCreate,
          // },
        },
      ]"
    ></export-options>
    <!-- REVIEW story-36-a: enable this when BE can handle sort requests!!! -->
    <!-- sortField="book_date" -->
    <book-entries
      @edit="onEdit"
      @destroy="onDestroy"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterOn="{
        byRelations: [
          'byBroker',
          'byDocument',
          'byTask',
          'byRegistry',
          'byInsurer',
          'byInsuranceAncillary',
          'byVariousAccounting',
          'byBookTag',
        ],
      }"
      :filterName="filterName"
      :ref="tableRef"
      :hasChecksButtonGroup="false"
      noActions
      sortField="book_date"
    ></book-entries>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import BookEntries from "@/components/tables/BookEntries";
// import ButtonGroupTable from "@/components/ButtonGroupTable";
import ExportOptions from "@/components/ExportOptions";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import CustomFilter from "@/components/form/CustomFilter";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { mapGetters, mapActions } from "vuex";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import { toInfoData } from "@/utils/transforms";
// import ReportsMixin from "@/mixins/ReportsMixin";
// import submitReportModal from "@/components/modals/submitReportModal";

export default {
  mixins: [CustomFiltersMixin, StorageGetterMixin],
  data() {
    return {
      exportUrl: null,
      myToggle: false,
      filter: this.initFilter(),
      filterName: "filterBookEntryCooperator",
      resource: "book_entries",
      repository: "book_entry",
      tableRef: "BookEntryCooperatorTableRef",
      cooperators: [],
      fields: [
        {
          key: "cooperator",
          label: this.getDictionary("cooperator"),
          // sortable: true,
          // sortKey: "byBroker.BUID",
        },

        {
          key: "book_date",
          label: this.getDictionary("book_date", "book_entry"),
          sortable: true,
          formatter: (value) => toLocaleDate(value),
          sortKey: "book_date",
        },
        {
          key: "document_code",
          label: this.getDictionary("code", "book_tag"),
        },
        {
          key: "insurance_policy_number",
          label: this.getDictionary("insurance_policy_number", "book_entry"),
          sortable: true,
          sortKey: "byInsurancePolicy.number",
        },
        {
          key: "customer",
          label: this.getDictionary("registry", "book_entry"),
          sortable: true,
          sortKey: "byRegistry.title",
        },
        {
          key: "product",
          label: this.getDictionary("insurance_risk"),
          sortable: true,
          sortKey: "byInsuranceRisk.title",
        },
        {
          key: "gross",
          label: this.getDictionary("gross", "book_entry"),
          formatter: (value) => toLocaleCurrency(value),
          tdClass: "text-right",
          sortable: true,
        },
        {
          key: "take",
          label: this.getDictionary("take", "book_entry"),
          formatter: (value) => toLocaleCurrency(value),
          tdClass: "text-center",
        },
        {
          key: "net",
          label: this.getDictionary("net", "book_entry"),
          formatter: (value) => toLocaleCurrency(value),
          tdClass: "text-right",
          thClass: "text-right",
          sortable: true,
        },
        {
          key: "tax",
          label: this.getDictionary("tax", "book_entry"),
          formatter: (value) => toLocaleCurrency(value),
          tdClass: "text-right",
          thClass: "text-right",
          sortable: true,
        },
        {
          key: "agency_gross",
          label: this.getDictionary("agency_gross"),
          tdClass: "text-right",
          thClass: "text-right",
        },
      ],
    };
  },
  components: {
    // ButtonGroupTable,
    ExportOptions,
    BaseSelect,
    BaseDatepicker,
    BookEntries,
    CustomFilter,
    // submitReportModal,
  },
  methods: {
    toInfoData,
    initFilter() {
      let init = {
        byCalendar: {
          from: null,
          to: null,
        },
        byBrokerEnhanced: {
          cooperators: [],
        },
      };
      return init;
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onEdit(item) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${item.id}`, item: item, mode: "I" },
      });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$refs[this.tableRef].fetch();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      // Select at least one cooperator
      if (!this.filter.byBrokerEnhanced.cooperators.length) {
        this.$showSnackbar({
          preset: "error",
          text: `Devi selezionare almeno un collaboratore`,
        });
        return;
      }
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );
      // quicksearch
      criteria = {};
      this.saveQuickSearchByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.myToggle = false;
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removeQuickSearchByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
    },
    selectAllCooperators() {
      this.filter.byBrokerEnhanced.cooperators = this.getCooperators().map(
        (cooperator) => cooperator.value
      );
    },
    deselectAllCooperators() {
      this.filter.byBrokerEnhanced.cooperators = [];
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("quicksearch", {
      saveQuickSearchByName: "saveByName",
      removeQuickSearchByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    ...mapGetters("auth", {
      getCooperators: "cooperators",
    }),
  },
  beforeMount() {
    /* */
    console.log("beforeMount index");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("cooperators"))
      resources.push(
        this.$store
          .dispatch(`${path}cooperators`)
          .then(() => {
            console.info(
              `background data "${path}cooperators" fetched correctly`
            );
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly");
          this.cooperators = this.getCooperators();
          this.filter.byBrokerEnhanced.cooperators = this.getCooperators().map(
            (cooperator) => cooperator.value
          );
          this.myToggle = true;
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.cooperators = this.getCooperators();
      this.filter.byBrokerEnhanced.cooperators = this.getCooperators().map(
        (cooperator) => cooperator.value
      );
      this.myToggle = true;
      this.isLoading = false;
    }
    /* */
  },
};
</script>
